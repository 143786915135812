import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import useInfinityScroll from 'application/hooks/use-infinity-scroll';
import { Toast } from 'ui';

import GET_NEWS_LIST, {
  GetNewsListQuery,
  GetNewsListQueryVars,
} from '../graphql/get-news-list';

const useNewsFeed = () => {
  const router = useRouter();

  const triggerRef = useRef<HTMLDivElement | null>(null);

  const [loading, setLoading] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      const savedLoading = localStorage.getItem('loading');
      return savedLoading !== null ? JSON.parse(savedLoading) : true;
    }
    return true;
  });

  const [hasMore, setHasMore] = useState(true);
  const [sportAlias, setSportAlias] = useState('');

  const { data, error, fetchMore } = useQuery<
    GetNewsListQuery,
    GetNewsListQueryVars
  >(GET_NEWS_LIST, {
    variables: { sportAlias },
    fetchPolicy: 'cache-first',
    onError: (error) => {
      Toast.error(error.message);
      setHasMore(false);
    },
  });

  const onLoadMore = async () => {
    if (
      data?.getNewsList.__typename === 'GetNewsList' &&
      data.getNewsList.news.length !== data.getNewsList.totalCount
    ) {
      setLoading(true);

      fetchMore({
        variables: { sportAlias, offset: data.getNewsList.news.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          if (fetchMoreResult.getNewsList.__typename === 'Error') {
            Toast.error(fetchMoreResult.getNewsList.error);
            return prev;
          }

          if (
            fetchMoreResult.getNewsList.__typename !== 'GetNewsList' ||
            prev.getNewsList.__typename !== 'GetNewsList'
          ) {
            return prev;
          }

          const newsLength =
            prev.getNewsList.news.length +
            fetchMoreResult.getNewsList.news.length;

          if (newsLength === fetchMoreResult.getNewsList.totalCount) {
            setHasMore(false);
          }

          const newNews = fetchMoreResult.getNewsList.news.filter(
            (el) => !el.isPinned
          );

          return {
            getNewsList: {
              __typename: 'GetNewsList',
              news: [...prev.getNewsList.news, ...newNews],
              totalCount: fetchMoreResult.getNewsList.totalCount,
            },
          };
        },
      }).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (data?.getNewsList.__typename === 'Error') {
      Toast.error(data.getNewsList.error);
      setHasMore(false);
    }
  }, [data, error]);

  useEffect(() => {
    if (router.query.url) {
      setSportAlias(router.query.url as string);
    }
  }, [router.query.url]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('loading', JSON.stringify(loading));
    }
  }, [loading]);

  useInfinityScroll({
    hasMore,
    triggerRef,
    callback: onLoadMore,
  });

  return { triggerRef, data, loading, fetchMore };
};

export default useNewsFeed;
