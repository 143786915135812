import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { Card } from 'ui';
import PinIcon from 'ui/assets/icons/16/pin.svg';
import { Text } from 'ui/v2/typography/text';

import css from './published-news-preview.module.scss';
import { OnePublishedNewsDataType } from '../../types/one-published-news-data-type';
import NewsFooter from '../news-footer/news-footer';
import NewsPreview from '../news-preview';

interface PublishedNewsPreviewProps {
  triggerRef?: MutableRefObject<HTMLDivElement | null>;
  news: Omit<OnePublishedNewsDataType, 'blocks'>;
  editor: boolean;
  isPinned?: boolean;
  likeNews: () => void;
  showComments: () => void;
  openNews: () => void;
  deleteNews?: () => void;
  editNews?: () => void;
  addToBookmarks: () => void;
  refs: MutableRefObject<{ [key: string]: HTMLDivElement | null }>;
}

const PublishedNewsPreview: React.FC<PublishedNewsPreviewProps> = ({
  news,
  editor,
  isPinned,
  likeNews,
  openNews,
  deleteNews,
  editNews,
  showComments,
  addToBookmarks,
  triggerRef,
  refs,
}) => {
  const {
    id,
    client,
    commentsCount,
    likesCount,
    isLiked,
    views,
    bookmarksInfo,
    previewUploadDescription,
    ...newsPreviewData
  } = news;

  const newsFooterData = {
    client,
    commentsCount,
    likesCount,
    isLiked,
    views,
    bookmarksInfo,
  };

  const newsRef = useRef<HTMLDivElement | null>(null);
  const [scrolledToLastVisited, setScrolledToLastVisited] = useState(false);

  useEffect(() => {
    refs.current[news.id] = newsRef.current;
  }, [news.id, refs]);

  useEffect(() => {
    const lastVisitedNewsId = localStorage.getItem('lastVisitedNewsId');

    if (lastVisitedNewsId === news.id && !scrolledToLastVisited) {
      setTimeout(() => {
        const offset = 20;
        const topPosition = newsRef.current?.getBoundingClientRect().top || 0;
        window.scrollTo({
          top: topPosition + window.pageYOffset - offset,
          behavior: 'auto',
        });
        setScrolledToLastVisited(true);

        localStorage.removeItem('lastVisitedNewsId');
      }, 500);
    }
  }, [news.id, scrolledToLastVisited]);

  const handleOpenNews = () => {
    localStorage.setItem('lastVisitedNewsId', news.id);
    openNews();
  };

  return (
    <Card className={css.Root} ref={triggerRef}>
      {isPinned && <PinIcon className={css.Pin} />}
      <div className={css.previewUploadDescription} ref={newsRef}>
        <NewsPreview
          newsPreviewData={newsPreviewData}
          editor={editor}
          openNews={handleOpenNews}
          deleteNews={deleteNews}
          editNews={editNews}
        />

        {previewUploadDescription && (
          <Text type="caption" className={css.Caption}>
            {previewUploadDescription}
          </Text>
        )}
      </div>

      <NewsFooter
        newsFooterData={newsFooterData}
        className={css.NewsFooter}
        likeNews={likeNews}
        showComments={showComments}
        addToBookmarks={addToBookmarks}
      />
    </Card>
  );
};

export default PublishedNewsPreview;
