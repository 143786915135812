import cx from 'classnames';
import { PropsWithChildren } from 'react';

import css from './sticky-column.module.scss';

interface StickyColumnProps extends PropsWithChildren {
  className?: string;
}
export const StickyColumn = ({ children, className }: StickyColumnProps) => {
  return <div className={cx(css.StickyColumn, className)}>{children}</div>;
};
