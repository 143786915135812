import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

export interface pinPublishedNewsMutation {
  pinPublishedNews:
    | {
        __typename: 'PinPublishedNews';
        news: OnePublishedNewsDataType;
      }
    | GraphqlErrorType;
}

export interface pinPublishedNewsQueryMutationVars {
  newsId: string;
  pin: boolean;
}

const PIN_PUBLISHED_NEWS = gql`
  mutation PinPublishedNews($newsId: ID!, $pin: Boolean!) {
    pinPublishedNews(newsId: $newsId, pin: $pin) {
      ... on PinPublishedNews {
        news {
          id
          title
          previewDescription
          previewUploadDescription
          previewImage
          previewVideo
          date
          category {
            id
            name
          }
          sport {
            id
            name
            icon
          }
          client {
            id
            name
            avatar
          }
          commentsCount
          likesCount
          isLiked
          isPinned
          bookmarksInfo {
            id
            inBookmarks
          }
          views
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default PIN_PUBLISHED_NEWS;
