import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

import { ClientType } from 'application/types/client-type';
import { formatNumber } from 'application/utils/numbers';
import { Bookmark, ClientAvatar, Comments, Likes } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { EyeIcon } from 'ui/icons';
import { ResponsiveFlex } from 'ui/responsive-flex/responsive-flex';

import css from './news-footer.module.scss';
import webApp from '../../../../application/web-app';
import { BookmarksInfoType } from '../../graphql/update-bookmarks-news';

interface NewsFooterDataType {
  client: ClientType;
  commentsCount: number;
  likesCount: number;
  isLiked: boolean;
  bookmarksInfo: BookmarksInfoType;
  views: number;
}

interface NewsFooterProps {
  newsFooterData: NewsFooterDataType;
  className?: string;
  classNameBookmarks?: string;
  likeNews: () => void;
  showComments?: () => void;
  addToBookmarks: () => void;
}

const NewsFooter: React.FC<NewsFooterProps> = ({
  newsFooterData,
  likeNews,
  showComments,
  addToBookmarks,
  className,
  classNameBookmarks,
}) => {
  const { client, commentsCount, likesCount, isLiked, views, bookmarksInfo } =
    newsFooterData;

  return (
    <ResponsiveFlex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      className={cx(css.Root, className)}
      gap={GridGap.x1}
    >
      <Flex alignItems="center" gap={GridGap.x1}>
        <Link
          href={webApp.router.getClientInfo(client.id)}
          passHref
          className={css.Author}
        >
          <ClientAvatar avatar={client.avatar} size="XS" />
          {client.name}
        </Link>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        gap={GridGap.x1}
        className={css.Panel}
      >
        <div className={cx(css.Bookmark, classNameBookmarks)}>
          <Bookmark
            bookmarksInfo={bookmarksInfo}
            addToBookmarks={addToBookmarks}
          />
        </div>
        <Comments
          commentsCount={formatNumber(commentsCount)}
          showComments={showComments}
        />
        <Flex
          alignItems="center"
          width="fit-content"
          className={css.ViewsContainer}
        >
          <span className={css.Views}>{formatNumber(views)}</span>
          <EyeIcon />
        </Flex>
        <Likes
          likesCount={formatNumber(likesCount)}
          isLiked={isLiked}
          setLike={likeNews}
        />
      </Flex>
    </ResponsiveFlex>
  );
};

export default NewsFooter;
