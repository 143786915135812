import { SortDirection } from 'ui/sorting-button/sorting-button.types';
import { VerticalScrollWrapper } from 'ui/vertical-scroll-wrapper/vertical-scroll-wrapper';

import PariListTableContent from './pari-list-table-content';
import PariListTableHeader from './pari-list-table-header';
import { MyPariModel } from '../../graphql/get-my-pari-info';
import { ClientPariListTab, PariSortBy } from '../../pari.types';

interface Props {
  pariList: MyPariModel[];
  currentTab?: ClientPariListTab;
  sort: { key: PariSortBy; sort: SortDirection };
  setSort: (args: { key: PariSortBy; sort: SortDirection }) => void;
}

export const ClientPariList = ({
  pariList,
  currentTab,
  setSort,
  sort,
}: Props) => {
  const columnsGrid =
    currentTab === ClientPariListTab.ACTIVE
      ? '45px minmax(280px, 1fr) 50px 90px 134px'
      : '45px minmax(280px, 1fr) 50px 90px 71px 104px';

  const columnsGridTablet =
    currentTab === ClientPariListTab.ACTIVE
      ? '80px minmax(280px, 1fr) 50px 90px 102px'
      : '80px minmax(280px, 1fr) 50px 90px 60px 72px';

  const columnsGridMobile =
    currentTab === ClientPariListTab.ACTIVE
      ? '45px minmax(280px, 1fr) 50px 90px 102px'
      : '45px minmax(280px, 1fr) 50px 90px 60px 72px';

  return (
    <VerticalScrollWrapper>
      <PariListTableHeader
        columnsGrid={columnsGrid}
        columnsGridTablet={columnsGridTablet}
        columnsGridMobile={columnsGridMobile}
        currentTab={currentTab}
        setSort={setSort}
        sort={sort}
      />
      <PariListTableContent
        columnsGrid={columnsGrid}
        columnsGridTablet={columnsGridTablet}
        columnsGridMobile={columnsGridMobile}
        currentTab={currentTab}
        pariList={pariList}
      />
    </VerticalScrollWrapper>
  );
};
