import React from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, Tabs } from 'ui';
import Card from 'ui/card';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import Pagination from 'ui/pagination';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { Text } from 'ui/v2/typography/text';
import { VerticalScrollWrapper } from 'ui/vertical-scroll-wrapper/vertical-scroll-wrapper';

import { ClientPariList } from './client-pari-list';
import css from './client-pari-list.module.scss';
import useClientPariList from '../../hooks/use-client-pari-list';
import { ClientPariListTab } from '../../pari.types';

const ClientPariListConnected = () => {
  const { t } = useTranslation(['common']);

  const { data, tabs, tab, setTab, sort, pagination, loading } =
    useClientPariList();

  const handleTabChange = (newTab: ClientPariListTab) => {
    setTab(newTab);
    pagination.setPage(1);
  };

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Loader />
      </Flex>
    );
  }

  if (data?.getMyPariInfo.__typename !== 'GetMyPariInfo') {
    return null;
  }

  return (
    <Card>
      <ResponsiveGrid gap={GridGap.x3} mobile={{ gap: GridGap.x2 }}>
        <VerticalScrollWrapper>
          <div className={css.Card}>
            <Tabs value={tab} items={tabs} onSelect={handleTabChange} />
          </div>
        </VerticalScrollWrapper>
        {data.getMyPariInfo.paries.length === 0 ? (
          <Text type="paragraph" textAlign="center">
            {t('common:labels:no_data')}
          </Text>
        ) : (
          <ClientPariList
            currentTab={tab}
            pariList={data.getMyPariInfo.paries}
            sort={sort.sort}
            setSort={sort.setSort}
          />
        )}
        {+data.getMyPariInfo.pariesCount > 15 && (
          <Flex justifyContent="center" className={css.Pagination}>
            <Pagination
              page={pagination.page}
              perPageLimit={15}
              setPage={pagination.setPage}
              totalItems={+data.getMyPariInfo.pariesCount}
            />
          </Flex>
        )}
      </ResponsiveGrid>
    </Card>
  );
};

export default ClientPariListConnected;
