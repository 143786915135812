import { MutableRefObject, useEffect } from 'react';

export interface UseInfinityScrollOptions {
  callback?: () => void;
  hasMore?: boolean;
  triggerRef: MutableRefObject<HTMLElement | null>;
}

const useInfinityScroll = ({
  callback,
  triggerRef,
  hasMore,
}: UseInfinityScrollOptions) => {
  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    const trigger = triggerRef.current;
    if (callback && trigger) {
      const options = {
        rootMargin: '0px',
        threshold: 0.8,
      };

      observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && hasMore) {
          callback();
          triggerRef.current = null;
        }
      }, options);

      observer.observe(trigger);
    }

    return () => {
      if (observer && trigger) {
        observer.unobserve(trigger);
      }
    };
  }, [callback, triggerRef, hasMore]);
};

export default useInfinityScroll;
