import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

export type OnePublishedNewsBasicDataType = Omit<
  OnePublishedNewsDataType,
  | 'views'
  | 'client'
  | 'isLiked'
  | 'likesCount'
  | 'inBookmarks'
  | 'commentsCount'
>;

export interface GetOnePublishedNewsQuery {
  getOnePublishedNews:
    | {
        __typename: 'GetOnePublishedNews';
        news: OnePublishedNewsBasicDataType;
      }
    | GraphqlErrorType;
}

export interface GetOnePublishedNewsQueryVars {
  id: string;
}

const GET_ONE_PUBLISHED_NEWS = gql`
  query GetOnePublishedNews($id: ID!) {
    getOnePublishedNews(id: $id) {
      ... on GetOnePublishedNews {
        news {
          id
          title
          date
          previewUploadDescription
          previewDescription
          previewImage
          previewVideo
          isPinned
          category {
            id
            name
          }
          sport {
            id
            name
            icon
          }
          blocks {
            type
            id
            data {
              text
              caption
              alignment
              level
              items
              style
              stretched
              file {
                url
              }
              html
              serviceName
              url
            }
          }
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_ONE_PUBLISHED_NEWS;
