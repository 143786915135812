import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Loader from 'ui/loader';

import css from './news-preview-video.module.scss';
import { debounce } from '../../../../application/utils/debounce';

interface Props {
  src: string;
}

const NewsPreviewVideo = ({ src }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<string>('');
  const [isVerticalVideo, setIsVerticalVideo] = useState<boolean>(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);

  const updateWidth = () => {
    setContainerWidth(String(containerRef?.current?.offsetWidth));
  };

  const debouncedUpdateWidth = debounce(() => {
    setContainerWidth(String(containerRef?.current?.offsetWidth));
  }, 100);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', debouncedUpdateWidth);

    return () => {
      window.removeEventListener('resize', debouncedUpdateWidth);
    };
  }, []);

  useEffect(() => {
    const videoElement = document.createElement('video');

    videoElement.onloadedmetadata = () => {
      const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;

      setIsVerticalVideo(aspectRatio <= 1);
    };

    videoElement.src = src;
  }, [src]);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div
      className={css.PreviewVideo}
      ref={containerRef}
      style={{ width: containerWidth }}
    >
      {!isVideoLoaded && (
        <div className={css.LoaderContainer}>
          <div className={css.Backdrop} />
          <Loader />
        </div>
      )}
      {isVerticalVideo && (
        <div className={css.BlurBackground}>
          <video
            className={css.VideoBackground}
            src={src}
            playsInline
            muted
            autoPlay
            loop
          />
        </div>
      )}
      <div
        className={cx(css.VideoContainer, {
          [css.VideoWithBackground]: isVerticalVideo,
        })}
      >
        <video
          className={cx(css.Video, {
            [css.VideoWithBackground]: isVerticalVideo,
          })}
          src={src}
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={handleVideoLoad}
        />
      </div>
    </div>
  );
};

export default NewsPreviewVideo;
