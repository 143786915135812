import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

import { PariSortBy } from '../pari.types';

export type MyPariModel = {
  date: string;
  finishedAt: string;
  pnl: string;
  tournament: {
    id: string;
    name: string;
    icon: string;
  };
  event: {
    id: string;
    name: string;
  } | null;
  result: {
    id: string;
    name: string;
  };
  outcome: {
    id: string;
    name: string;
    win: boolean | null;
  };
  amount: number;
  winAmount: number;
  coeff: number;
};

export interface GetMyPariInfoQuery {
  getMyPariInfo:
    | {
        __typename: 'GetMyPariInfo';
        paries: MyPariModel[];
        pariesCount: string;
      }
    | GraphqlErrorType;
}

export interface GetMyPariInfoQueryVars {
  sort: {
    page: number;
    sortDirection?: 'ASC' | 'DESC';
    sortBy?: PariSortBy;
    isActive?: boolean;
    status?: 'active' | 'finished' | 'canceled';
  };
}

const GET_MY_PARI_INFO = gql`
  query GetMyPariInfo($sort: GetMyPariInfoSortQuery!) {
    getMyPariInfo(sort: $sort) {
      __typename
      ... on GetMyPariInfo {
        paries {
          date
          finishedAt
          pnl
          tournament {
            id
            name
            icon
          }
          event {
            id
            name
          }
          result {
            id
            name
          }
          outcome {
            id
            name
            win
          }
          amount
          winAmount
          coeff
        }
        pariesCount
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_MY_PARI_INFO;
