import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppConfig } from 'application/context/app-config-provider';
import { useAppModals } from 'application/context/app-modals-provider';
import type { MyClientModel } from 'application/graphql/get-my-client';
import useWindowDimensions from 'application/hooks/use-window-dimensions';
import webApp, { LanguageType } from 'application/web-app';
import { LanguageSelect } from 'ui';
import Button from 'ui/button';
import ClientAvatar from 'ui/client-avatar';
import ProfileMenu from 'ui/header/profile-menu';
import SportsMenu from 'ui/header/sports-menu';
import { ArrowDownIcon, CreateNewsIcon } from 'ui/icons';

import CupBanner from './cub-banner.svg';
import Cup from './cup.svg';
import css from './header.module.scss';
import MobileMenu from './mobile-menu';
import { isTouchDevice } from '../../application/utils/is-touch-device';

export const enum MobileMenuItemType {
  LANGUAGE = 'language',
  SPORTS = 'sports',
  MORE = 'more',
  NONE = '',
}

interface HeaderProps {
  headerUrls: { label: string; url: string }[];
  user: MyClientModel | null;
  appLanguages: LanguageType[];
  userLogout: () => void;
  onCreateNewsModalOpen: () => void;
}

const Header: React.FC<HeaderProps> = ({
  user,
  appLanguages,
  userLogout,
  onCreateNewsModalOpen,
}) => {
  const { push, query } = useRouter();
  const { t } = useTranslation(['header', 'auth']);

  const { appConfig } = useAppConfig();
  const { width } = useWindowDimensions();
  const { setSignInOpen } = useAppModals();

  const touchDevice = isTouchDevice();

  const [isSportsMenuOpen, setSportsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);

  const [mobileMenuActiveItem, setMobileMenuAActiveItem] = useState(
    MobileMenuItemType.NONE
  );

  const sports = appConfig?.sports || [];
  const adaptivePoint = width < 1230;

  const mma = sports.find((el) => el.alias === 'mma');
  const football = sports.find((el) => el.alias === 'football');
  const otherSports = sports.filter(
    (el) => el.alias !== 'mma' && el.alias !== 'football'
  );

  const onClickMMA = () => {
    mma && push(webApp.router.getSport(mma.alias));
  };

  const onClickFootball = () => {
    football && push(webApp.router.getSport(football.alias));
  };

  useEffect(() => {
    if (mobileMenuActiveItem && isProfileMenuOpen) {
      setProfileMenuOpen(false);
    }
  }, [mobileMenuActiveItem]);

  const openHowVote = () => {
    push('/news/29');
    setMobileMenuAActiveItem(MobileMenuItemType.NONE);
  };

  const openContestNews = () => {
    push('/news/157');
  };

  return (
    <header className={css.Root}>
      <nav className={css.Nav}>
        <ul className={css.Menu}>
          <li>
            <ul className={css.SubMenu}>
              {adaptivePoint && (
                <li className={css.MenuItem}>
                  <MobileMenu
                    openHowVote={openHowVote}
                    activeItem={mobileMenuActiveItem}
                    setActiveItem={setMobileMenuAActiveItem}
                    onCreateNewsModalOpen={onCreateNewsModalOpen}
                    appLanguages={appLanguages}
                    sports={sports}
                  />
                </li>
              )}

              <li className={css.MenuItem}>
                <Link href="/" passHref>
                  <h1 className={css.Logo} />
                </Link>
              </li>

              <li className={css.SportsMenu}>
                <ul className={css.SubMenu}>
                  {mma && (
                    <li
                      className={cx(
                        adaptivePoint ? css.Hide : css.MenuItem,
                        css.SportsItem,
                        { [css.Active]: query.url === mma.alias }
                      )}
                      onClick={onClickMMA}
                    >
                      {mma.name}
                    </li>
                  )}
                  {football && (
                    <li
                      className={cx(
                        adaptivePoint ? css.Hide : css.MenuItem,
                        css.SportsItem,
                        { [css.Active]: query.url === football.alias }
                      )}
                      onClick={onClickFootball}
                    >
                      {football.name}
                    </li>
                  )}

                  {otherSports.length > 1 && (
                    <li
                      className={cx(
                        adaptivePoint
                          ? css.Hide
                          : [css.MenuItem, css.SportsMenuButton]
                      )}
                      onMouseEnter={() => setSportsMenuOpen(true)}
                    >
                      <ArrowDownIcon
                        className={cx(
                          css.ArrowDownIcon,
                          isSportsMenuOpen && css.IconRotate
                        )}
                      />
                      {isSportsMenuOpen && (
                        <SportsMenu
                          sports={otherSports}
                          closeMenu={() => setSportsMenuOpen(false)}
                        />
                      )}
                    </li>
                  )}
                </ul>
              </li>

              <li className={cx(adaptivePoint ? css.Hide : css.MenuItem)}>
                <Link href={webApp.router.getCalendarOfEvents()} passHref>
                  {t('header:events')}
                </Link>
              </li>

              {/* <li className={cx(adaptivePoint ? css.Hide : css.MenuItem)}>
               <div>{t('header:rating')}</div>
               </li>*/}

              <li
                className={cx(
                  adaptivePoint ? css.Hide : css.MenuItem,
                  css.HowVote
                )}
                onClick={openHowVote}
              >
                {t('header:how_vote')}
              </li>
            </ul>
          </li>
          <li>
            <ul className={css.SubMenu}>
              <li
                className={cx({
                  [css.Hide]: !user || adaptivePoint,
                  [css.MenuItem]: user && !adaptivePoint,
                  [css.AlignRight]: user && !adaptivePoint,
                })}
              >
                <Button
                  className={css.CreateNewsButton}
                  onClick={onCreateNewsModalOpen}
                >
                  <CreateNewsIcon />
                  {t('header:create_article')}
                </Button>
              </li>
              <li className={css.CupBannerContainer} onClick={openContestNews}>
                {width > 680 ? <CupBanner /> : <Cup />}
              </li>
              <li
                className={cx(
                  adaptivePoint
                    ? css.Hide
                    : [css.MenuItem, css.AlignRight, css.LanguageSelect]
                )}
              >
                <LanguageSelect />
              </li>

              <li
                className={cx(
                  css.Balance,
                  css.AlignRight,
                  user && width > 510 ? css.MenuItem : css.Hide
                )}
                onClick={() => push(webApp.router.getBalance())}
              >
                {user?.balanceVusd} vts
              </li>
              <li
                className={cx(
                  user ? [css.MenuItem, css.ProfileMenuButton] : css.Hide
                )}
                onClick={() =>
                  !mobileMenuActiveItem && setProfileMenuOpen((prev) => !prev)
                }
                onMouseEnter={() => {
                  if (touchDevice || mobileMenuActiveItem) return;
                  setProfileMenuOpen(true);
                }}
              >
                <ClientAvatar
                  avatar={user?.avatar || null}
                  size="S"
                  color="dark"
                  isActive={isProfileMenuOpen}
                />
                <ArrowDownIcon
                  className={cx(
                    css.ArrowDownIcon,
                    isProfileMenuOpen && css.IconRotate
                  )}
                />
                {user && isProfileMenuOpen && (
                  <ProfileMenu
                    username={user?.username}
                    logout={userLogout}
                    closeMenu={() => setProfileMenuOpen(false)}
                  />
                )}
              </li>

              <li
                className={cx(user ? css.Hide : css.MenuItem, css.AlignRight)}
              >
                <button
                  className={css.LoginButton}
                  onClick={() => setSignInOpen(true)}
                >
                  {t('auth:sign_in')}
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
