import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

export interface GetNewsListQuery {
  getNewsList:
    | {
        __typename: 'GetNewsList';
        news: OnePublishedNewsDataType[];
        totalCount: number;
      }
    | GraphqlErrorType;
}

export interface GetNewsListQueryVars {
  sportAlias?: string;
  offset?: number;
}

const GET_NEWS_LIST = gql`
  query GetNewsList($sportAlias: String, $offset: Int) {
    getNewsList(sportAlias: $sportAlias, offset: $offset) {
      ... on GetNewsList {
        totalCount
        news {
          id
          title
          previewDescription
          previewUploadDescription
          previewImage
          previewVideo
          date
          category {
            id
            name
          }
          sport {
            id
            name
            icon
          }
          client {
            id
            name
            avatar
          }
          commentsCount
          likesCount
          isLiked
          isPinned
          bookmarksInfo {
            id
            inBookmarks
          }
          views
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_NEWS_LIST;
